@use "../../scss/" as *;

.footer {
  position: relative;
  padding: 6rem 2rem;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  &__content {
    max-width: 1000px;

    &__logo {
      @include flex(center, center);
      margin-bottom: 1rem;
    }

    &__menus {
      display: flex;
      justify-content: center;
    }

    &__menu {
      font-size: 1rem;
      font-weight: 400;

      & > * ~ * {
        margin-top: 1rem;
      }
    }
  }
}
